<template>
  <div class="d-flex border-secondary rounded">
    <v-container fluid class="d-flex pa-1 pr-0">
      <v-row no-gutters>
        <v-col cols="6" sm="6">
          <c-select
            class="black--text"
            width="100"
            hide-details="auto"
            item-text="name"
            item-value="id"
            return-object
            :value="messenger.id"
            :items="availableItem"
            :placeholder="$t('personal.select_messenger')"
            :error-messages="errorName"
            @input="changeMessenger($event, 'name')"
          />
        </v-col>
        <v-col cols="6" sm="6" class="pl-1">
          <c-text-field
            hide-details="auto"
            :value="messenger.nickname"
            :error-messages="errorNickname"
            @input="changeMessenger($event, 'nickname')"
          />
        </v-col>
      </v-row>
      <v-btn class="flex-shrink-0 px-1 align-self-center" icon color="error" @click="$emit('remove')">
        <v-icon size="14">
          $close
        </v-icon>
      </v-btn>
    </v-container>
  </div>
</template>

<script>
  import CSelect from '@clickadilla/components/ui/CSelect.vue'
  import CTextField from '@clickadilla/components/ui/CTextField.vue'
  import { mapState } from 'vuex'

  export default {
    name: 'MessengerSelect',
    components: { CSelect, CTextField },
    props: {
      availableMessengers: {
        type: Array,
        required: true
      },
      messenger: {
        type: Object,
        required: true
      },
      messengers: {
        type: Array,
        required: true
      },
      index: {
        type: Number,
        required: true
      }
    },
    computed: {
      ...mapState('personal', ['errors']),

      availableItem() {
        return this.availableMessengers.filter(
          (msg) => msg.id === this.messenger.id
            || !this.messengers.some((messenger) => messenger.id === msg.id)
        )
      },
      errorNickname() {
        const str = `messengers.${this.index}.nickname`
        return this.errors[str] ? this.errors[str] : ''
      },
      errorName() {
        const str = `messengers.${this.index}.id`
        return this.errors[str] ? this.errors[str] : ''
      }
    },
    methods: {
      changeMessenger(value, key) {
        const copyMessenger = JSON.parse(JSON.stringify(this.messenger))
        copyMessenger[key] = value[key] || value
        copyMessenger.id = value.id || this.messenger.id
        this.$emit('update', copyMessenger)
      }
    }
  }
</script>

<template>
  <div>
    <v-data-table
      class="black--text"
      :headers="headers"
      :items="dateItems"
      :loading="isLoading"
      :options.sync="options"
      :mobile-breakpoint="0"
      hide-default-footer
      :server-items-length="statisticsTotal"
      @page-count="$emit('page-count', $event)"
      @update:options="fetchStatistics()"
    >
      <template #item.date="{ item }">
        <span>
          {{ dateFormatter(item.date) }}
        </span>
      </template>
      <template v-if="dateItems.length" #body.append>
        <tr class="white--text total-row">
          <th>
            <span class="text-body-2 font-weight-bold">{{ $t('main.total') }}</span>
          </th>
          <th>
            <span class="text-body-2 font-weight-bold">{{ toFixedByDefault(totalCount) }}</span>
          </th>
        </tr>
      </template>
    </v-data-table>
    <data-table-footer
      v-if="statisticsTotal > options.itemsPerPage"
      :page-count="pageCount"
      :items-per-page="options.itemsPerPage"
      :current-page="options.page"
      @change-items-per-page="changePaginator($event, 'itemsPerPage')"
      @change-current-page="changePaginator($event, 'page')"
    />
  </div>
</template>

<script>
  import moment from 'moment'
  import axios from 'axios'
  import DataTableFooter from '@clickadilla/components/ui/DataTableFooter.vue'
  import { toFixedByDefault } from '@clickadilla/components/utils/to-fixed-by-default.js'
  import { referralProgramRepository } from '@/services/repository-factory.js'

  const { statistics } = referralProgramRepository
  export default {
    name: 'DatesStatisticsTable',
    components: { DataTableFooter },
    props: {
      filters: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        statistics,
        dateItems: [],
        isLoading: false,
        statisticsTotal: 0,
        pageCount: 0,
        requestStatistics: null,
        options: {
          page: 1,
          itemsPerPage: 15,
          sortBy: [],
          sortDesc: []
        }
      }
    },
    computed: {
      totalCount() {
        return this.dateItems.reduce((acc, num) => acc + +num.reward, 0)
      },
      headers() {
        return [
          {
            value: 'date',
            text: 'Date',
            class: 'black--text'
          },
          {
            value: 'reward',
            text: this.$t('personal.earnings'),
            sortable: false,
            class: 'black--text',
            width: 100
          }
        ]
      }
    },
    watch: {
      filters: {
        deep: true,
        handler() {
          this.fetchStatistics()
        }
      }
    },
    methods: {
      dateFormatter(date) {
        return moment(date).format('Y-MM-DD')
      },
      changePaginator(val, key) {
        this.options[key] = val
        if (key === 'itemsPerPage') {
          this.options.page = 1
        }

        this.fetchStatistics()
      },
      async fetchStatistics(capturedOptions) {
        this.isLoading = true
        const requestBody = {
          headers: this.headers,
          options: capturedOptions ?? this.options
        }
        try {
          const response = await this.statistics({
            ...requestBody,
            startDate: this.filters.period.start,
            endDate: this.filters.period.end,
            groupBy: this.filters.groupBy
          }, 'dates')
          this.dateItems = response.data.map((item) => ({
            email: item.email,
            reward: toFixedByDefault(Number(item.reward)),
            date: item.date
          }))
          this.statisticsTotal = response.recordsFiltered
        } catch (error) {
          if (!axios.isCancel(error)) {
            this.$showErrorNotification(error.message)
          }
        }
        this.isLoading = false
      },
      toFixedByDefault
    }
  }
</script>

<style lang="scss" scoped>
  ::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > .total-row,
  ::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > .total-row:hover {
    background-color: var(--v-info-base) !important;
  }
</style>

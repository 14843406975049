<template>
  <v-card :loading="isLoading" elevation="0" class="pa-4">
    <v-card-title class="text-subtitle-2 font-weight-medium black--text justify-center mb-3 pa-0">
      <v-icon size="18" class="mr-2 primary--text">
        $human-meeting
      </v-icon>
      {{ $t('personal.your_referrals') }}
    </v-card-title>
    <div class="count-number font-weight-bold text-center primary--text">
      {{ count }}
    </div>
  </v-card>
</template>

<script>
  export default {
    name: 'ReferralsCountCard',
    props: {
      isLoading: Boolean,
      count: {
        type: Number,
        default: 0
      }
    }
  }
</script>

<style lang="scss" scoped>
  .count-number {
    font-size: 40px !important;
  }
</style>

<template>
  <div>
    <v-card elevation="0" class="px-5 py-4 fill-height">
      <v-card-title class="text-h6 font-weight-bold black--text pa-0 mb-4">
        {{ $t('personal.title_two_factor_authentication') }}
        <v-progress-circular
          v-if="saveIsLoading"
          size="20"
          width="2"
          indeterminate
          color="primary"
        />
      </v-card-title>
      <v-card-text class="pa-0 pb-16 pb-lg-0">
        <v-skeleton-loader :loading="fetchIsLoading" type="list-item-avatar">
          <c-toggle-buttons
            v-model="currentMethod"
            mandatory
            :buttons="availableMethods"
            :error-messages="errors.twoFactorAuthenticationMethod"
          />
          <div class="black--text pt-4" style="position: absolute">
            {{ descriptionMethod }}
          </div>
        </v-skeleton-loader>
      </v-card-text>
    </v-card>
    <google-authentication
      v-if="googleAuthenticationIsShown"
      :is-shown="googleAuthenticationIsShown"
      @close-dialog="googleAuthenticationIsShown = false"
      @set-google2fa="changeTwoFactorAuthenticationMethod('google')"
    />
  </div>
</template>

<script>
  import CToggleButtons from '@clickadilla/components/ui/CToggleButtons.vue'
  import { mapActions, mapState } from 'vuex'
  import GoogleAuthentication from '@/views/Personal/GoogleAuthentication.vue'

  export default {
    name: 'TwoFactorMethodSelect',
    components: { GoogleAuthentication, CToggleButtons },
    data() {
      return {
        googleAuthenticationIsShown: false
      }
    },
    computed: {
      ...mapState('settings', ['twoFactorAuthenticationMethods']),
      ...mapState('personal', [
        'fetchIsLoading',
        'twoFactorAuthenticationMethod',
        'errors',
        'saveIsLoading'
      ]),
      availableMethods() {
        const methods = [...this.twoFactorAuthenticationMethods]
        const translatedMethods = {
          none: this.$t('personal.method_none'),
          email: 'Email',
          google: 'Google'
        }
        methods.unshift('none')
        return methods.map((method) => ({
          text: translatedMethods[method],
          value: method
        }))
      },
      currentMethod: {
        get() {
          return this.twoFactorAuthenticationMethod || 'none'
        },
        set(value) {
          this.changeAuthenticationMethod(value)
        }
      },
      descriptionMethod() {
        if (this.currentMethod === 'email') {
          return this.$t('personal.method_email_description')
        }
        return ''
      }
    },
    methods: {
      ...mapActions('personal', ['changeTwoFactorAuthenticationMethod']),
      async changeAuthenticationMethod(val) {
        if (val === 'google') {
          const authenticationMethod = this.currentMethod === 'none' ? '' : this.currentMethod
          this.changeTwoFactorAuthenticationMethod([])
          await this.$nextTick()
          this.changeTwoFactorAuthenticationMethod(authenticationMethod)
          this.googleAuthenticationIsShown = true
          return
        }
        const authenticationMethod = val === 'none' ? '' : val
        this.changeTwoFactorAuthenticationMethod(authenticationMethod)
      }
    }
  }
</script>

<template>
  <v-dialog :value="dialogIsShown" max-width="342" @input="closeDialog()">
    <v-card class="d-flex flex-column align-end pa-3">
      <v-icon size="18" @click="closeDialog()">
        $close-outlined
      </v-icon>
      <v-card-title class="align-self-center">
        {{ dialogTitles.title }}
      </v-card-title>

      <v-card-text class="text-center black--text pb-7">
        <div class="mb-8">
          {{ dialogTitles.body }}
        </div>
        <span class="text-body-1 font-weight-medium">
          {{ dialogTitles.question }}
        </span>
      </v-card-text>

      <v-card-actions class="align-self-center">
        <c-btn class="primary" width="121" :label="dialogTitles.agreeButtonText" @click="$emit('agree')" />
        <c-btn class="secondary-lighten" plain width="121" :label="dialogTitles.disagreeButtonText" @click="closeDialog()" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import CBtn from '@clickadilla/components/ui/CBtn.vue'

  export default {
    name: 'ChangePasswordDialog',
    components: {
      CBtn
    },
    props: {
      dialogIsShown: {
        type: Boolean,
        required: true
      }
    },
    computed: {
      dialogTitles() {
        return {
          title: this.$t('personal.send_confirm_title'),
          body: this.$t('personal.send_confirm_body'),
          question: this.$t('personal.send_confirm_body_question'),
          disagreeButtonText: this.$t('main.disagree'),
          agreeButtonText: this.$t('main.agree')
        }
      }
    },
    methods: {
      closeDialog() {
        this.$emit('close')
      }
    }
  }
</script>

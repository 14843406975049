<template>
  <div class="border-primary rounded pa-1">
    <div class="text-h6 black--text font-weight-bold text-center pt-2 mb-5">
      {{ $t('personal.referral_title') }}
    </div>
    <v-container fluid>
      <v-row>
        <v-col cols="12" sm="12" lg="6">
          <link-card class="fill-height" :link="referral.linkUrl" :is-loading="isLoading" />
        </v-col>

        <v-col cols="12" sm="6" lg="3">
          <percent-card class="fill-height" :percent="referral.percent" :is-loading="isLoading" />
        </v-col>

        <v-col cols="12" sm="6" lg="3">
          <count-card class="fill-height" :count="referral.referralsCount" :is-loading="isLoading" />
        </v-col>
      </v-row>
    </v-container>
    <div class="pa-3">
      <c-btn
        :label="$t('personal.detailed_statistics')"
        color="primary"
        class="expand-button font-weight-regular mx-auto mb-3 d-flex flex-row-reverse"
        :icon-props="{
          icon: referralTablesAreHidden ? '$arrow-down' : '$arrow-up',
          class: 'ml-2'
        }"
        text
        @click="referralTablesAreHidden = !referralTablesAreHidden"
      />
      <keep-alive>
        <tabs v-if="!referralTablesAreHidden" :link-url="referral.linkUrl" />
      </keep-alive>
    </div>
  </div>
</template>

<script>
  import CBtn from '@clickadilla/components/ui/CBtn.vue'
  import LinkCard from '@/views/Personal/ReferralProgram/LinkCard.vue'
  import PercentCard from '@/views/Personal/ReferralProgram/PercentCard.vue'
  import CountCard from '@/views/Personal/ReferralProgram/CountCard.vue'
  import Tabs from './Tabs/Index.vue'
  import referralRepository from '@/services/repositories/referral-program-repository.js'
  import handleErrors from '@/services/handleErrors.js'
  import Referral from '@/services/classes/Referral.js'

  export default {
    name: 'ReferralProgram',
    components: {
      LinkCard,
      PercentCard,
      CountCard,
      Tabs,
      CBtn
    },
    data() {
      return {
        referralTablesAreHidden: true,
        isLoading: false,
        referral: new Referral()
      }
    },
    created() {
      this.fetchInfo()
    },
    methods: {
      async fetchInfo() {
        this.isLoading = true
        try {
          const response = await referralRepository.index()
          this.referral = new Referral(response)
        } catch (error) {
          handleErrors(error)
        }
        this.isLoading = false
      }
    }
  }
</script>

<style lang="scss" scoped>
  :deep .expand-button > .v-btn__content {
    display: flex;
    flex-direction: row-reverse;
  }
</style>

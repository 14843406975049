<template>
  <v-card elevation="0" class="px-5 py-4">
    <v-card-title class="text-h6 black--text font-weight-bold pa-0 mb-5">
      {{ $t('personal.title_notifications') }}
      <v-progress-circular v-if="saveIsLoading" size="20" width="2" indeterminate color="primary" />
    </v-card-title>
    <v-card-text class="pa-0">
      <v-skeleton-loader :loading="fetchIsLoading" type="list-item-avatar, list-item-avatar">
        <c-switch
          :input-value="adActionsNotifications"
          class="mb-4"
          dense
          :disabled="saveIsLoading"
          @change="changeAdActionsNotifications(!!$event)"
        >
          <template #label>
            <span :class="['text-body-2', adActionsNotifications ? 'primary--text' : 'black--text']">
              {{ $t('personal.ad_notifications') }}
            </span>
          </template>
        </c-switch>

        <c-switch
          dense
          :input-value="campaignActionsNotifications"
          class="mb-4"
          :disabled="saveIsLoading"
          @change="changeCampaignActionsNotifications(!!$event)"
        >
          <template #label>
            <span :class="['text-body-2', campaignActionsNotifications ? 'primary--text' : 'black--text']">
              {{ $t('personal.campaign_notifications') }}
            </span>
          </template>
        </c-switch>
        <c-switch
          dense
          :input-value="campaignTooltipsNotifications"
          :disabled="saveIsLoading"
          @change="changeCampaignTooltipNotifications(!!$event)"
        >
          <template #label>
            <span :class="['text-body-2', campaignTooltipsNotifications ? 'primary--text' : 'black--text']">
              {{ $t('personal.tooltip_notifications') }}
            </span>
          </template>
        </c-switch>
      </v-skeleton-loader>
    </v-card-text>
  </v-card>
</template>

<script>
  import { mapActions, mapState } from 'vuex'
  import CSwitch from '@clickadilla/components/ui/CSwitch.vue'

  export default {
    name: 'Notifications',
    components: {
      CSwitch
    },
    computed: {
      ...mapState('personal', [
        'fetchIsLoading',
        'campaignActionsNotifications',
        'adActionsNotifications',
        'campaignTooltipsNotifications',
        'saveIsLoading'
      ])
    },
    methods: {
      ...mapActions('personal', [
        'changeCampaignActionsNotifications',
        'changeAdActionsNotifications',
        'changeCampaignTooltipNotifications'
      ])
    }
  }
</script>

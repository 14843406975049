<template>
  <div>
    <div class="black white--text font-weight-bold title pa-4 mb-3 rounded">
      {{ $t('personal.title') }}
    </div>
    <v-container fluid>
      <v-row class="mb-1 mx-n5">
        <v-col cols="12" class="pb-0 mb-3">
          <main-profile class="fill-height" />
        </v-col>
        <v-col v-if="adNetwork.telegramBotName" cols="12" md="6" class="py-0 mb-3">
          <integrations class="fill-height" />
        </v-col>
        <v-col v-if="cardVerificationRequiredForPayments" class="py-0 mb-3" cols="12" md="6">
          <verifications class="fill-height" />
        </v-col>
        <v-col class="py-0 mb-3" cols="12" md="6">
          <two-factor-method-select class="fill-height" />
        </v-col>
        <v-col class="py-0 mb-3" cols="12" md="6">
          <notifications class="fill-height" />
        </v-col>
        <v-col class="py-0 mb-3" cols="12" md="6">
          <type-profile class="fill-height" />
        </v-col>
        <v-col v-if="accountTypeIsCompany" class="py-0 mb-3" cols="12">
          <information-company class="fill-height" />
        </v-col>
        <v-col v-if="trafficProviderSubscription.id" class="py-0 mb-3" cols="12" md="6">
          <subscriptions
            class="fill-height"
            :subscription-plan="trafficProviderSubscription"
            @update="fetchUserProfile()"
          />
        </v-col>
        <v-col
          v-if="recurringPaymentsCustomer && recurringPaymentsCustomer.paymentMethodId"
          class="py-0 mb-3"
          cols="12"
          md="6"
        >
          <auto-payment class="fill-height" />
        </v-col>
      </v-row>
    </v-container>
    <div class="px-1">
      <c-btn
        class="mt-4 mb-14 mb-sm-10 text-body-1 font-weight-medium"
        :block="!isDesktop"
        depressed
        color="primary"
        height="46"
        :loading="saveIsLoading || fetchIsLoading"
        :label="$t('personal.save_button_text')"
        @click="updateUserProfile()"
      />
      <referral-program v-if="adNetworkHasSectionReferralProgram" />
    </div>
  </div>
</template>

<script>
  import CBtn from '@clickadilla/components/ui/CBtn.vue'
  import { mapActions, mapGetters, mapState } from 'vuex'
  import MainProfile from './MainProfile/Index.vue'
  import Notifications from './Notifications.vue'
  import AutoPayment from './AutoPayment.vue'
  import Verifications from './Verifications.vue'
  import Subscriptions from './Subscriptions.vue'
  import TypeProfile from '@/views/Personal/TypeProfile.vue'
  import TwoFactorMethodSelect from '@/views/Personal/TwoFactorMethodSelect.vue'
  import InformationCompany from '@/views/Personal/InformationCompany.vue'
  import ReferralProgram from './ReferralProgram/Index.vue'
  import guardSections from '@/types/guard-sections.js'
  import Integrations from '@/views/Personal/Integrations.vue'

  export default {
    name: 'Personal',
    components: {
      Integrations,
      Subscriptions,
      InformationCompany,
      TwoFactorMethodSelect,
      TypeProfile,
      Verifications,
      MainProfile,
      Notifications,
      AutoPayment,
      CBtn,
      ReferralProgram
    },
    computed: {
      ...mapState('settings', ['cardVerificationRequiredForPayments', 'adNetwork']),
      ...mapState('personal', [
        'recurringPaymentsCustomer',
        'trafficProviderSubscription',
        'saveIsLoading',
        'fetchIsLoading'
      ]),
      ...mapGetters('personal', ['accountTypeIsCompany']),
      adNetworkHasSectionReferralProgram() {
        return this.adNetwork.guardSections.some(({ name }) => name === guardSections.REFERRAL_PROGRAM)
      },
      isDesktop() {
        return this.$vuetify.breakpoint.lgAndUp
      }
    },
    created() {
      this.fetchUserProfile()
    },
    methods: {
      ...mapActions('personal', ['fetchUserProfile', 'saveUserProfile']),
      updateUserProfile() {
        this.saveUserProfile()
      }
    }
  }
</script>

<template>
  <v-card elevation="0" class="px-5 py-4 pb-5">
    <v-card-title class="text-h6 black--text font-weight-bold pa-0 mb-5">
      {{ $t('personal.title_type_profile_card') }}
      <v-spacer />
      <v-progress-circular v-if="saveIsLoading" size="20" width="2" indeterminate color="primary" />
    </v-card-title>
    <v-card-text class="pa-0">
      <v-skeleton-loader :loading="fetchIsLoading" type="list-item-avatar, list-item-avatar">
        <c-toggle-buttons
          v-model="profileTypesModel"
          mandatory
          class="mb-4"
          :buttons="profileTypes"
          :error-messages="errors.accountType"
        />
        <div class="text-body-2 black--text">
          {{ accountType === 'personal' ? $t('personal.text_personal_type') : $t('personal.text_company_type') }}
        </div>
      </v-skeleton-loader>
    </v-card-text>
  </v-card>
</template>

<script>
  import { mapActions, mapGetters, mapState } from 'vuex'
  import CToggleButtons from '@clickadilla/components/ui/CToggleButtons.vue'

  export default {
    name: 'TypeProfile',
    components: {
      CToggleButtons
    },
    computed: {
      ...mapState('personal', [
        'fetchIsLoading',
        'accountType',
        'accountTypes',
        'saveIsLoading',
        'errors'
      ]),
      ...mapGetters('personal', ['accountTypeIsPersonal', 'accountTypeIsCompany']),
      profileTypesModel: {
        get() {
          return this.accountType
        },
        set(value) {
          this.changeAccountType(value)
        }
      },
      profileTypes() {
        return [
          {
            text: this.$t('personal.personal_profile'),
            value: 'personal'
          },
          {
            text: this.$t('personal.company_profile'),
            value: 'company'
          }
        ]
      }
    },
    methods: {
      ...mapActions('personal', ['changeAccountType', 'saveUserProfile'])
    }
  }
</script>

<template>
  <v-data-table
    class="black--text"
    :headers="headers"
    :items="referralItems"
    :options.sync="options"
    :loading="isLoading"
    :mobile-breakpoint="0"
    hide-default-footer
    :server-items-length="referralItemsTotal"
    @page-count="pageCount = $event"
    @update:options="debouncedFetchTableReferral()"
  >
    <template #footer>
      <data-table-footer
        :items-per-page="options.itemsPerPage"
        :current-page="options.page"
        :page-count="pageCount"
        @change-items-per-page="changePaginator($event, 'itemsPerPage')"
        @change-current-page="changePaginator($event, 'page')"
      />
    </template>
  </v-data-table>
</template>

<script>
  import DataTableFooter from '@clickadilla/components/ui/DataTableFooter.vue'
  import debounce from 'debounce'
  import axios from 'axios'
  import referralRepository from '@/services/repositories/referral-program-repository.js'

  export default {
    name: 'ReferralsTab',
    components: {
      DataTableFooter
    },
    data() {
      return {
        pageCount: 0,
        isLoading: false,
        referralItems: [],
        debouncedFetchTableReferral: null,
        referralItemsTotal: 0,
        options: {
          sortBy: [],
          sortDesc: [],
          page: 1,
          itemsPerPage: 15
        }
      }
    },
    computed: {
      headers() {
        return [
          {
            value: 'email',
            text: 'Email',
            class: 'black--text',
            filters: false
          },
          {
            value: 'created_at',
            text: this.$t('personal.created_at'),
            class: 'black--text',
            width: 150
          }
        ]
      }
    },
    created() {
      this.debouncedFetchTableReferral = debounce(() => {
        this.fetchTableReferral()
      }, 500)
    },
    methods: {
      changePaginator(val, key) {
        this.options[key] = val
        if (key === 'itemsPerPage') {
          this.options.page = 1
        }

        this.debouncedFetchTableReferral()
      },
      async fetchTableReferral() {
        this.isLoading = true
        const requestBody = {
          headers: this.headers,
          options: this.options
        }
        try {
          const response = await referralRepository.referralTable(requestBody)
          this.referralItems = response.items
          this.referralItemsTotal = response.totalItemsCount
        } catch (error) {
          if (!axios.isCancel(error)) {
            this.$showErrorNotification(error.message)
          }
        }
        this.isLoading = false
      }
    }
  }
</script>

<template>
  <v-card elevation="0" class="pa-0">
    <v-card-title class="text-h6 black--text font-weight-bold pt-4 pb-0 px-5 mb-1">
      {{ $t('main.autorecharge_title') }}

      <v-spacer />
      <v-progress-circular v-if="saveIsLoading" size="20" width="2" indeterminate color="primary" />
    </v-card-title>

    <v-card-text class="pa-0">
      <charge-securion-pay-field
        v-if="autoPaymentMethod"
        :auto-payment="autoPayment"
        :is-loading="fetchIsLoading"
        :recurring-payments-customer="recurringPaymentsCustomer"
        :is-title-show="false"
        :is-background="false"
      />
    </v-card-text>
  </v-card>
</template>

<script>
  import { mapState } from 'vuex'
  import ChargeSecurionPayField from '@/components/AddFunds/ChargeSecurionPayField.vue'

  export default {
    name: 'AutoPayment',
    components: { ChargeSecurionPayField },
    data() {
      return {
        autoPayment: true
      }
    },
    computed: {
      ...mapState('personal', ['fetchIsLoading', 'recurringPaymentsCustomer', 'saveIsLoading']),
      autoPaymentMethod() {
        return !!this.recurringPaymentsCustomer?.paymentMethodId
      }
    }
  }
</script>

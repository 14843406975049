<template>
  <v-card elevation="0" class="px-5 py-4">
    <v-card-title class="text-h6 black--text font-weight-bold pa-0 mb-3">
      {{ $t('personal.dsp_subscriptions') }}
    </v-card-title>
    <v-card-text class="pa-0 d-sm-flex flex-wrap align-center">
      <v-chip
        class="mb-3 mb-sm-0 mr-sm-3"
        label
        outlined
      >
        <span class="black--text mr-2">
          {{ $t('personal.paid_until') }}:
        </span>
        <span class="font-weight-medium black--text">
          {{ subscriptionPlan.paidUntil }}
        </span>
      </v-chip>
      <div>
        <v-chip
          :color="subscriptionPlan.plan.textColor"
          text-color="black"
          label
          outlined
        >
          <span class="mr-2">
            {{ $t('personal.subscription_plan') }}:
          </span>
          <span :style="`color: ${subscriptionPlan.plan.textColor}`" v-html="planTranslation.name" />
        </v-chip>
        <c-btn
          icon
          class="ml-2"
          color="primary"
          :icon-props="{
            icon: '$config'
          }"
          :to="{ name: 'Providers' }"
        />
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
  import { mapState } from 'vuex'
  import CBtn from '@clickadilla/components/ui/CBtn.vue'

  export default {
    name: 'Subscriptions',
    components: { CBtn },
    props: {
      subscriptionPlan: {
        type: Object,
        required: true
      }
    },
    computed: {
      ...mapState('personal', ['trafficProviderSubscription']),
      ...mapState('settings', ['locale']),
      planTranslation() {
        return this.trafficProviderSubscription.plan.pickCurrentLocale(this.locale)
      }
    }
  }
</script>

<template>
  <c-btn
    :label="$t('personal.copy_code')"
    class="text-decoration-underline text-body-2"
    text
    color="primary"
    @click="copyRef()"
  />
</template>

<script>
  import CBtn from '@clickadilla/components/ui/CBtn.vue'

  export default {
    name: 'CodeComponent',
    components: { CBtn },
    props: {
      url: {
        type: String,
        required: true
      },
      imgUrl: {
        type: String,
        required: true
      },
      bannerHeight: {
        type: [String, Number],
        default: 0
      },
      bannerWidth: {
        type: [String, Number],
        default: 0
      }
    },
    methods: {
      async copyRef() {
        // eslint-disable-next-line vue/max-len
        const refCode = `<a href="${this.url}"><img width="${this.bannerWidth}" height="${this.bannerHeight}" src="${this.imgUrl}" target="_blank"></a>`
        try {
          await this.$copyText(refCode)
          this.$showSuccessNotification(this.$t('personal.copied_successfully'))
        } catch {
          this.$showErrorNotification(this.$t('personal.error_occurred'))
        }
      }
    }
  }
</script>

<template>
  <v-card elevation="0">
    <v-card-title class="white d-flex justify-center px-3">
      <c-btn
        v-for="(button, index) in referralButtons"
        :key="index"
        :class="[
          'mb-2 text-body-2',
          {
            'black--text': button.value !== currentTab,
            'ml-2': index !== 0
          }
        ]"
        :color="currentTab === button.value ? 'primary' : 'secondary'"
        :label="button.text"
        depressed
        @click="currentTab = button.value"
      />
    </v-card-title>
    <keep-alive>
      <component :is="currentTabComponent" :link-url="linkUrl" />
    </keep-alive>
  </v-card>
</template>

<script>
  import { mapState } from 'vuex'
  import CBtn from '@clickadilla/components/ui/CBtn.vue'
  import ReferralsTab from './ReferralsTab.vue'
  import StatisticsTab from './StatisticsTab.vue'
  import BannersTab from './BannersTab.vue'

  export default {
    name: 'Tabs',
    components: {
      CBtn,
      ReferralsTab,
      StatisticsTab,
      BannersTab
    },
    props: {
      linkUrl: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        currentTab: 'referrals'
      }
    },
    computed: {
      ...mapState('settings', ['adNetwork']),
      referralButtons() {
        return [
          { value: 'referrals', isShown: true, text: this.$t('personal.referrals') },
          { value: 'statistics', isShown: true, text: this.$t('personal.statistics') },
          { value: 'banners', isShown: this.adNetwork.settings.enableReferralBanners, text: this.$t('personal.banners') }
        ].filter((tab) => tab.isShown)
      },
      currentTabComponent() {
        return {
          referrals: ReferralsTab,
          statistics: StatisticsTab,
          banners: BannersTab
        }[this.currentTab]
      }
    }
  }
</script>

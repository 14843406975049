var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-title',{staticClass:"white d-flex justify-center px-3"},_vm._l((_vm.referralButtons),function(button,index){return _c('c-btn',{key:index,class:[
        'mb-2 text-body-2',
        {
          'black--text': button.value !== _vm.currentTab,
          'ml-2': index !== 0
        }
      ],attrs:{"color":_vm.currentTab === button.value ? 'primary' : 'secondary',"label":button.text,"depressed":""},on:{"click":function($event){_vm.currentTab = button.value}}})}),1),_c('keep-alive',[_c(_vm.currentTabComponent,{tag:"component",attrs:{"link-url":_vm.linkUrl}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-card elevation="0" class="px-4 py-5">
    <v-card-title class="text-h6 font-weight-bold pa-0 mb-7 mb-lg-2">
      {{ $t('personal.title_information_company_card') }}
      <v-progress-circular v-if="saveIsLoading" size="20" width="2" indeterminate color="primary" />
    </v-card-title>
    <v-card-text class="pa-0">
      <v-row>
        <v-col cols="12" sm="6" lg="3" class="py-0 py-5">
          <c-text-field
            label-bold
            hide-details="auto"
            :value="companyName"
            :error-messages="errors.companyName"
            :placeholder="$t('personal.company_name_placeholder')"
            @input="changeCompanyName($event)"
          >
            <template #label>
              <span class="black--text font-weight-medium">
                {{ $t('personal.company_name') }}
              </span>
              <span class="error--text">*</span>
            </template>
          </c-text-field>
        </v-col>
        <v-col cols="12" sm="6" lg="3" class="py-0 py-5">
          <c-text-field
            label-bold
            hide-details="auto"
            :value="companyAddress"
            :placeholder="$t('personal.company_address_placeholder')"
            :error-messages="errors.companyAddress"
            @input="changeCompanyAddress($event)"
          >
            <template #label>
              <span class="black--text font-weight-medium">
                {{ $t('personal.company_address') }}
              </span>
              <span class="error--text">*</span>
            </template>
          </c-text-field>
        </v-col>
        <v-col cols="12" sm="6" lg="3" class="py-0 py-5">
          <c-text-field
            label-bold
            hide-details="auto"
            :value="companySite"
            :placeholder="$t('personal.company_site_placeholder')"
            :error-messages="errors.companySite"
            @input="changeCompanySite($event)"
          >
            <template #label>
              <span class="black--text font-weight-medium">
                {{ $t('personal.company_site') }}
              </span>
              <span class="error--text">*</span>
            </template>
          </c-text-field>
        </v-col>
        <v-col cols="12" sm="6" lg="3" class="py-0 py-5">
          <c-text-field
            label-bold
            label="VAT"
            hide-details="auto"
            :value="vat"
            :placeholder="$t('personal.company_vat_placeholder')"
            :error-messages="errors.vat"
            @input="changeVat($event)"
          />
        </v-col>

        <v-col cols="12" md="6">
          <c-textarea
            label-bold
            :label="$t('personal.additional_info')"
            outlined
            hide-details="auto"
            :value="additionalInfo"
            :placeholder="$t('personal.additional_info_placeholder')"
            :error-messages="errors.additionalInfo"
            @input="changeAdditionalInfo($event)"
          />
        </v-col>
        <v-col cols="12" md="6">
          <v-alert
            text
            color="info"
            class="mb-0 mt-md-6"
          >
            {{ $t('personal.information_message') }}
          </v-alert>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
  import CTextField from '@clickadilla/components/ui/CTextField.vue'
  import CTextarea from '@clickadilla/components/ui/CTextarea.vue'
  import { mapActions, mapState } from 'vuex'

  export default {
    name: 'InformationCompany',
    components: {
      CTextarea,
      CTextField
    },
    computed: {
      ...mapState('personal', [
        'companyName',
        'companyAddress',
        'companySite',
        'vat',
        'additionalInfo',
        'saveIsLoading',
        'errors'
      ])
    },
    methods: {
      ...mapActions('personal', [
        'changeCompanyName',
        'changeCompanyAddress',
        'changeCompanySite',
        'changeVat',
        'changeAdditionalInfo'
      ])
    }
  }
</script>

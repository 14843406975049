<template>
  <v-dialog persistent max-width="800" :value="isShown" @input="$emit('close-dialog')">
    <v-card class="min-height pa-4">
      <v-card-title class="pa-2">
        <close-button right absolute @click="$emit('close-dialog')" />
      </v-card-title>
      <v-container v-show="step === 1">
        <v-row>
          <v-col cols="12" md="4" class="d-flex flex-column text-center">
            <v-img :src="google2fa.image" contain height="200" />
            {{ google2fa.secret_key }}
          </v-col>
          <v-col cols="12" md="8">
            <p class="text-h6">
              {{ $t('personal.g2fa.title') }}
            </p>
            <ol class="mb-3">
              <li>{{ $t('personal.g2fa.list_1') }}</li>
              <li class="my-2">
                {{ $t('personal.g2fa.list_2') }}
              </li>
              <li>{{ $t('personal.g2fa.list_3') }}</li>
            </ol>
            <c-btn color="primary" :label="$t('main.continue')" @click="changeStep(2)" />
          </v-col>
        </v-row>
      </v-container>

      <div v-show="step === 2" class="text-center">
        <p class="text-h6">
          {{ $t('personal.g2fa.enter_the_code') }}
        </p>
        <p>{{ $t('personal.g2fa.enter_code_g2fa') }}</p>
        <div class="d-flex flex-column mx-auto align-center mb-4">
          <v-otp-input
            ref="otpInput"
            v-model="authenticationCode"
            class="max-width-300"
            length="6"
            :disabled="validateCodeIsLoading"
            :error-messages="errors.code"
            @keydown.enter="validateCode()"
            @finish="validateCode()"
          />
          <v-input hide-details="auto" :error-messages="errors.code" class="text-center" />
        </div>

        <c-btn
          class="mb-4"
          color="primary"
          :loading="validateCodeIsLoading"
          :label="$t('main.continue')"
          @click="validateCode()"
        />
        <br />
        <c-btn
          text
          color="info"
          :label="$t('main.back')"
          :disabled="validateCodeIsLoading"
          @click="changeStep(1)"
        />
      </div>
      <div v-if="step === 3" class="d-flex align-center min-height-300 justify-center">
        <v-icon size="43">
          $check
        </v-icon>
        <div class="text-h5 primary--text font-weight-medium ml-2">
          {{ $t('personal.g2fa.done') }}
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
  import CBtn from '@clickadilla/components/ui/CBtn.vue'
  import { personalRepository } from '@/services/repository-factory.js'
  import CloseButton from '@/components/CloseButton.vue'
  import { showErrorNotification } from '@/plugins/global-methods.js'

  export default {
    name: 'GoogleAuthentication',
    components: { CloseButton, CBtn },
    props: {
      isShown: {
        type: Boolean,
        require: true
      }
    },
    data() {
      return {
        google2fa: {},
        authenticationCode: null,
        step: 1,
        validateCodeIsLoading: false,
        errors: {}
      }
    },
    created() {
      this.fetchGoogle2fa()
    },
    methods: {
      changeStep(step) {
        this.step = step
        if (step === 2) {
          this.$nextTick(() => {
            this.$refs.otpInput.focus()
          })
        }
      },
      async fetchGoogle2fa() {
        this.google2fa = await personalRepository.google2fa()
      },
      async validateCode() {
        this.validateCodeIsLoading = true
        try {
          await personalRepository.google2faValidate({ code: this.authenticationCode })
          this.changeStep(3)
          this.$emit('set-google2fa')
        } catch (error) {
          this.authenticationCode = null
          if (error.response?.data?.errors) {
            this.errors = error.response?.data?.errors
          } else {
            showErrorNotification(error.message)
          }
        }
        this.validateCodeIsLoading = false
      }
    }
  }
</script>

<style lang="scss" scoped>
  .min-height {
    min-height: 100px;
  }
  .min-height-300 {
    min-height: 300px;
  }
  .max-width-300 {
    max-width: 300px;
  }
</style>

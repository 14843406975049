<template>
  <div>
    <div class="text-body-2 font-weight-medium black--text pl-md-1">
      {{ $t('personal.messenger_title') }}
    </div>
    <v-row no-gutters>
      <v-col
        v-for="(messenger, i) in userMessengers"
        :key="messenger.id"
        cols="12"
        sm="7"
        md="6"
        lg="4"
        class="mb-2 mb-sm-0 pa-md-1"
      >
        <messenger-select
          class="mt-2 mt-lg-0 mr-lg-2 mb-lg-0"
          :messengers="userMessengers"
          :available-messengers="messengers"
          :messenger="messenger"
          :index="i"
          @update="updateMessenger(i, $event)"
          @remove="removeMessenger(i)"
        />
      </v-col>
      <v-col sm="5" md="12" lg="auto" class="align-self-sm-end d-flex justify-sm-end align-self-lg-center pr-md-1">
        <c-btn
          :disabled="!userAvailableMessengers.length"
          :block="isMobile"
          class="mt-sm-2 mt-lg-0"
          height="32"
          depressed
          outlined
          color="primary"
          :label="$t('personal.add')"
          @click="addMessenger()"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import CBtn from '@clickadilla/components/ui/CBtn.vue'
  import { mapActions, mapState } from 'vuex'
  import MessengerSelect from '@/views/Personal/MainProfile/MessengerSelect.vue'

  export default {
    name: 'Messengers',
    components: { MessengerSelect, CBtn },
    computed: {
      ...mapState('personal', ['userMessengers']),
      ...mapState('collections', ['messengers']),
      userAvailableMessengers() {
        if (this.userMessengers.length) {
          const messengersById = this.userMessengers.map((userMessenger) => userMessenger.id)
          return this.messengers.filter((messenger) => !messengersById.includes(messenger.id))
        }
        return this.messengers
      },
      isMobile() {
        return this.$vuetify.breakpoint.xs
      },
      isTablet() {
        return this.$vuetify.breakpoint.sm
      }
    },
    async created() {
      await this.fetchMessengersList()
    },
    methods: {
      ...mapActions('collections', ['fetchMessengersList']),
      ...mapActions('personal', [
        'setUserMessengers',
        'removeUserMessenger'
      ]),
      addMessenger() {
        const copyUserMessengers = JSON.parse(JSON.stringify(this.userMessengers))
        if (this.userAvailableMessengers.length) {
          copyUserMessengers.push(this.userAvailableMessengers[0])
        }
        this.setUserMessengers(copyUserMessengers)
      },
      updateMessenger(index, newMessenger) {
        const copyUserMessengers = JSON.parse(JSON.stringify(this.userMessengers))
        copyUserMessengers[index] = newMessenger
        this.setUserMessengers(copyUserMessengers)
      },
      removeMessenger(removingMsgIndex) {
        this.removeUserMessenger(removingMsgIndex)
      }
    }
  }
</script>

<template>
  <div class="d-flex flex-column justify-space-between">
    <div>
      <v-data-table
        class="black--text"
        :headers="headers"
        :items="emailItems"
        :loading="isLoading"
        :options.sync="options"
        :mobile-breakpoint="0"
        hide-default-footer
        :server-items-length="statisticsTotal"
        @page-count="$emit('page-count', $event)"
        @update:options="fetchStatistics()"
      >
        <template v-if="emailItems.length" #body.append>
          <tr class="white--text total-row">
            <th>
              <span class="text-body-2 font-weight-bold">{{ $t('main.total') }}</span>
            </th>
            <th>
              <span class="text-body-2 font-weight-bold">{{ toFixedByDefault(totalCount) }}</span>
            </th>
          </tr>
        </template>
      </v-data-table>
    </div>
    <div>
      <data-table-footer
        v-if="statisticsTotal > options.itemsPerPage"
        :page-count="pageCount"
        :items-per-page="options.itemsPerPage"
        :current-page="options.page"
        @change-items-per-page="changePaginator($event, 'itemsPerPage')"
        @change-current-page="changePaginator($event, 'page')"
      />
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  import { toFixedByDefault } from '@clickadilla/components/utils/to-fixed-by-default.js'
  import DataTableFooter from '@clickadilla/components/ui/DataTableFooter.vue'
  import { referralProgramRepository } from '@/services/repository-factory.js'

  const { statistics } = referralProgramRepository
  export default {
    name: 'EmailStatisticsTable',
    components: { DataTableFooter },
    props: {
      filters: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        statistics,
        emailItems: [],
        isLoading: false,
        statisticsTotal: 0,
        requestStatistics: null,
        pageCount: 0,
        options: {
          page: 1,
          itemsPerPage: 15,
          sortBy: [],
          sortDesc: []
        }
      }
    },
    computed: {
      totalCount() {
        return this.emailItems.reduce((acc, num) => acc + +num.reward, 0)
      },
      headers() {
        return [
          {
            value: 'email',
            text: 'Email',
            class: 'black--text'
          },
          {
            value: 'reward',
            text: this.$t('personal.earnings'),
            sortable: false,
            class: 'black--text',
            width: 100
          }
        ]
      }
    },
    watch: {
      filters: {
        deep: true,
        handler() {
          this.fetchStatistics()
        }
      }
    },
    methods: {
      changePaginator(val, key) {
        this.options[key] = val
        if (key === 'itemsPerPage') {
          this.options.page = 1
        }

        this.fetchStatistics()
      },
      async fetchStatistics() {
        this.isLoading = true
        const requestBody = {
          headers: this.headers,
          options: this.options
        }
        try {
          const response = await this.statistics({
            ...requestBody,
            startDate: this.filters.period.start,
            endDate: this.filters.period.end,
            groupBy: 'referral'
          }, 'users')
          this.emailItems = response?.data?.map((item) => ({
            email: item.email,
            reward: toFixedByDefault(Number(item.reward)),
            date: item.date
          })) || []
          this.$emit('referral-items', this.emailItems)
          this.statisticsTotal = response.recordsFiltered
        } catch (error) {
          if (!axios.isCancel(error)) {
            this.$showErrorNotification(error.message)
          }
        }
        this.isLoading = false
      },
      toFixedByDefault
    }
  }
</script>

<style lang="scss" scoped>
::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > .total-row,
::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > .total-row:hover {
  background-color: var(--v-info-base) !important;
}
</style>

<template>
  <v-container fluid>
    <v-row class="px-3 py-5">
      <v-col v-if="adNetwork.settings.referralBanners['160x600']" cols="12" sm="6">
        <div class="wrapper-mobile d-flex flex-column align-center border-secondary rounded pa-3 mb-8">
          <div class="black--text text-body-2">
            160 x 600
          </div>
          <code-component
            class="mb-3"
            banner-height="600"
            banner-width="160"
            :img-url="adNetwork.settings.referralBanners['160x600']"
            :url="linkUrl"
          />
          <v-img
            class="banner mt-3"
            max-width="160"
            :src="adNetwork.settings.referralBanners['160x600']"
          />
        </div>
      </v-col>
      <v-col v-if="adNetwork.settings.referralBanners['300x250']" cols="12" sm="6">
        <div class="wrapper-mobile d-flex flex-column align-start align-sm-center border-secondary rounded pa-3 mb-8">
          <div class="black--text text-body-2">
            300 x 250
          </div>
          <code-component
            class="mb-3"
            banner-height="250"
            banner-width="300"
            :img-url="adNetwork.settings.referralBanners['300x250']"
            :url="linkUrl"
          />
          <v-img
            class="banner mt-3"
            max-width="300"
            :src="adNetwork.settings.referralBanners['300x250']"
          />
        </div>
      </v-col>
      <v-col v-if="adNetwork.settings.referralBanners['728x90']" cols="12">
        <div class="wrapper-mobile d-flex flex-column align-start align-md-center border-secondary rounded pa-3 mb-8">
          <div class="align-self-center text-center">
            <div class="black--text text-body-2">
              728 x 90
            </div>
            <code-component
              class="mb-3"
              banner-height="90"
              banner-width="728"
              :img-url="adNetwork.settings.referralBanners['728x90']"
              :url="linkUrl"
            />
          </div>
          <v-img
            max-width="728"
            class="banner mt-3"
            :src="adNetwork.settings.referralBanners['728x90']"
          />
        </div>
      </v-col>
      <v-col v-if="adNetwork.settings.referralBanners['320x100']" cols="12">
        <div class="wrapper-mobile d-flex flex-column align-start align-md-center border-secondary rounded pa-3 mb-8">
          <div class="align-self-center text-center">
            <div class="black--text text-body-2">
              320 x 100
            </div>
            <code-component
              class="mb-3"
              banner-height="100"
              banner-width="320"
              :img-url="adNetwork.settings.referralBanners['320x100']"
              :url="linkUrl"
            />
          </div>
          <v-img
            class="banner mt-3"
            max-width="320"
            :src="adNetwork.settings.referralBanners['320x100']"
          />
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapState } from 'vuex'
  import CodeComponent from './CodeComponent.vue'

  export default {
    name: 'ReferralsBanner',
    components: {
      CodeComponent
    },
    props: {
      linkUrl: {
        type: String,
        default: null
      }
    },
    computed: {
      ...mapState('settings', ['adNetwork'])
    }
  }
</script>

<style lang="scss" scoped>
  .wrapper-mobile {
    overflow: auto !important;
  }
</style>

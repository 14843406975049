<template>
  <v-container fluid>
    <v-row class="justify-center">
      <v-col sm="7">
        <date-picker-select v-model="filters.period" with-presets buttons />
      </v-col>
    </v-row>
    <custom-chart
      type="bar"
      :options="chartOptions"
      :series="series"
      height="350"
      class="custom-chart__z-index mt-4"
    />
    <v-row>
      <v-col cols="12" md="6">
        <users-statistics-table :filters="filters" @referral-items="statisticItems = $event" />
      </v-col>
      <v-col cols="12" md="6">
        <dates-statistics-table :filters="filters" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import DatePickerSelect from '@clickadilla/components/DatePickerSelect/index.vue'
  import moment from 'moment/moment.js'
  import UsersStatisticsTable from './Tables/UsersStatisticsTable.vue'
  import DatesStatisticsTable from './Tables/DatesStatisticsTable.vue'
  import customChart from '@/components/CustomChart.vue'

  export default {
    name: 'StatisticsTab',
    components: {
      DatePickerSelect,
      customChart,
      DatesStatisticsTable,
      UsersStatisticsTable
    },
    data() {
      return {
        statisticItems: [],
        defaultChartOptions: {
          stroke: {
            width: [4],
            curve: 'smooth'
          }
        },
        pageCount: 0,
        filters: {
          period: {
            start: '',
            end: ''
          },
          groupBy: 'date'
        }
      }
    },
    computed: {
      getReward() {
        return {
          type: 'category',
          categories: this.statisticItems.map((item) => item.email)
        }
      },
      series() {
        const data = this.statisticItems.map((item) => item.reward)
        return [
          {
            name: 'Reward',
            data
          }
        ]
      },
      chartOptions() {
        return {
          ...this.defaultChartOptions,
          xaxis: this.getReward,
          yaxis: [{ title: { text: 'Reward' } }]
        }
      }
    },
    created() {
      this.filters.period = {
        start: moment().add({ months: -1 }).format('YYYY-MM-DD'),
        end: moment().format('YYYY-MM-DD')
      }
    }
  }
</script>

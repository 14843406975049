export default class Referral {
  linkUrl = ''
  percent = null
  referralsCount = null

  constructor(data) {
    if (!data) return

    this.linkUrl = data.link_url ?? ''
    this.percent = data.percent ?? null
    this.referralsCount = data.referrals_count ?? null
  }
}

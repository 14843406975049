<template>
  <v-card :loading="isLoading" elevation="0" class="pa-4">
    <v-card-title class="text-subtitle-2 black--text font-weight-medium justify-center mb-3 pa-0 text-no-wrap">
      <v-icon size="18" class="mr-2 primary--text">
        $percent
      </v-icon>
      {{ $t('personal.your_percent') }}
    </v-card-title>
    <div class="count-number font-weight-bold text-center primary--text">
      {{ shownValue }}
    </div>
  </v-card>
</template>

<script>
  export default {
    name: 'PercentCard',
    props: {
      isLoading: {
        type: Boolean,
        required: true
      },
      percent: {
        type: Number,
        default: null
      }
    },
    computed: {
      shownValue() {
        return this.percent === null ? '-' : `${(this.percent * 100).toFixed()}%`
      }
    }
  }
</script>

<style lang="scss" scoped>
  .count-number {
    font-size: 40px !important;
  }
</style>

<template>
  <v-card elevation="0" class="py-4 px-5">
    <confirm-dialog ref="verifyAnotherDialog" @confirm="verifyAnother">
      <template #title>
        {{ $t('personal.dialog_title') }}
      </template>
      <template #body>
        {{ $t('personal.dialog_body.answer') }}<br />
        {{ $t('personal.dialog_body.warning') }}
        <span class="warning--text">{{ $t('personal.dialog_body.canceled') }}</span>.
      </template>
    </confirm-dialog>
    <v-card-title class="text-h6  black--text font-weight-bold pa-0 mb-5">
      {{ $t('personal.title_verifications_card') }}
      <verification-status-chip class="ml-3" padding-y="0" @verified-status-handler="setVerifiedStatus($event)" />
    </v-card-title>
    <v-card-text class="black--text pa-0">
      <v-skeleton-loader :loading="fetchIsLoading" type="paragraph, actions">
        {{ $t('personal.text_verifications') }}
        <div v-for="type in verificationTypes" :key="type.title" class="mt-2">
          <c-btn
            v-if="statusIsVerified"
            block
            outlined
            large
            depressed
            color="primary"
            :label="$t('personal.verify_another_button_text')"
            @click="openDialogHandler()"
          />
          <c-btn
            v-else
            block
            outlined
            large
            depressed
            color="primary"
            class="text-body-1 font-weight-medium"
            :to="{ name: 'Verifications', params: { type: type.title } }"
            :label="$t('personal.verify_button_text')"
          />
        </div>
      </v-skeleton-loader>
    </v-card-text>
  </v-card>
</template>

<script>
  import { mapState } from 'vuex'
  import CBtn from '@clickadilla/components/ui/CBtn.vue'
  import ConfirmDialog from '@/components/ConfirmDialog.vue'
  import verificationsRepository from '@/services/repositories/verifications-repository.js'
  import VerificationStatusChip from '@/components/VerificationStatusChip.vue'
  import routeNames from '@/types/route-names.js'

  export default {
    name: 'Verifications',
    components: {
      ConfirmDialog,
      CBtn,
      VerificationStatusChip
    },
    data() {
      return {
        statusIsVerified: false
      }
    },
    computed: {
      ...mapState('personal', ['fetchIsLoading', 'verificationStatuses']),
      verificationTypes() {
        return [{ title: 'card' }]
      }
    },
    methods: {
      async verifyAnother() {
        await verificationsRepository.delete()
        await this.$router.push({ name: routeNames.VERIFICATIONS, params: { type: 'card' } })
      },
      setVerifiedStatus(isVerified) {
        this.statusIsVerified = isVerified
      },
      openDialogHandler() {
        this.$refs.verifyAnotherDialog.ask()
      }
    }
  }
</script>

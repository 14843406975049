<template>
  <v-dialog v-model="isShown" max-width="342" @input="closeDialog()">
    <v-card class="d-flex flex-column align-end pa-3">
      <v-icon size="18" @click="closeDialog()">
        $close-outlined
      </v-icon>
      <div class="d-flex flex-column align-center px-6">
        <v-card-title class="align-self-center">
          {{ $t('personal.change_email') }}
        </v-card-title>

        <v-card-text class="text-center text-body-1 black--text pa-0 mb-8">
          {{ $t('personal.email_change') }}
        </v-card-text>

        <v-card-actions class="align-self-center">
          <c-btn class="primary" width="254" :label="$t('personal.contact_manager')" @click="onContactButtonClick()" />
        </v-card-actions>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
  import CBtn from '@clickadilla/components/ui/CBtn.vue'

  export default {
    name: 'ChangePasswordDialog',
    components: {
      CBtn
    },
    props: {
      dialogIsShown: {
        type: Boolean,
        required: true
      }
    },
    computed: {
      isShown: {
        get() {
          return this.dialogIsShown
        },
        set() {
          this.$emit('close')
        }
      }
    },
    methods: {
      onContactButtonClick() {
        this.$emit('open-chat')
        this.closeDialog()
      },
      closeDialog() {
        this.$emit('close')
      }
    }
  }
</script>

<template>
  <v-card elevation="0" class="py-4 px-5">
    <v-card-title class="text-h6  black--text font-weight-bold pa-0 mb-5">
      {{ $t('personal.integrations') }}
    </v-card-title>
    <v-card-text class="black--text pa-0">
      <div class="d-flex flex-wrap align-center">
        <c-info-btn
          :text="$t('personal.telegram_info')"
          class="ml-1 mt-n1"
          color="info-lighten"
          hover-color="info"
          is-tooltip
        />
        <v-chip
          class="ma-2"
          label
          outlined
        >
          <v-icon left size="24" color="info">
            $telegram
          </v-icon>
          Telegram
        </v-chip>
        <v-progress-circular
          v-if="fetchIsLoading && !isLoading"
          width="5"
          size="30"
          color="primary"
          indeterminate
          class="pb-16"
        />
        <c-btn
          v-else-if="subscribeTelegram"
          color="secondary-darken"
          class="white--text"
          depressed
          :loading="isLoading"
          :label="$t('personal.unsubscribe')"
          @click="unsubscribeTelegram()"
        />
        <subscribe-telegram v-else :gtm-event="GTM_EVENTS.TELEGRAM_SUBSCRIBE_PROFILE" @subscribe="btnUpdateIsShown = true" />
        <c-btn
          v-if="btnUpdateIsShown && !subscribeTelegram && !fetchIsLoading"
          color="primary"
          icon
          :icon-props="{
            icon: '$update',
            size: '18'
          }"
          :loading="fetchIsLoading"
          @click="fetchUserProfile()"
        />
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
  import CInfoBtn from '@clickadilla/components/ui/CInfoBtn.vue'
  import CBtn from '@clickadilla/components/ui/CBtn.vue'
  import { mapActions, mapState } from 'vuex'
  import { GTM_EVENTS } from '@clickadilla/components/constants/gtm-events.js'
  import SubscribeTelegram from '@/components/SubscribeTelegram.vue'
  import { personalRepository } from '@/services/repository-factory.js'
  import handleErrors from '@/services/handleErrors.js'
  import gtmPush from '@/services/utils/gtm-push.js'

  export default {
    name: 'Integrations',
    components: { SubscribeTelegram, CInfoBtn, CBtn },
    data() {
      return {
        GTM_EVENTS,
        isLoading: false,
        btnUpdateIsShown: false
      }
    },
    computed: {
      ...mapState('personal', ['fetchIsLoading', 'subscribeTelegram'])
    },
    methods: {
      ...mapActions('personal', ['fetchUserProfile']),
      async unsubscribeTelegram() {
        this.isLoading = true
        try {
          await personalRepository.unsubscribeTelegram()
          gtmPush({ event: GTM_EVENTS.TELEGRAM_UNSUBSCRIBE })
          await this.fetchUserProfile()
        } catch (error) {
          handleErrors(error)
        }
        this.isLoading = false
      }
    }
  }
</script>

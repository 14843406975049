<template>
  <v-card :loading="isLoading" elevation="0" class="px-3 py-4">
    <v-card-title class="text-subtitle-2 black--text font-weight-medium d-flex justify-center mb-4 pa-0">
      <v-icon size="18" class="mr-2 primary--text">
        $infinity
      </v-icon>
      {{ $t('personal.referral_link') }}
    </v-card-title>

    <v-card-text class="pa-0 d-sm-flex">
      <c-text-field :value="link" readonly solo flat hide-details class="mb-1 mb-sm-0 flex-sm-grow-1" height="46">
        <template #append>
          <copy-button :text-copied="link" />
        </template>
      </c-text-field>

      <div
        v-if="link"
        :class="[
          'd-flex justify-space-between align-center border-secondary rounded px-3 py-2',
          { 'width-224 ml-1': !isMobile }
        ]"
      >
        <span class="text-subtitle-2 black--text font-weight-medium">{{ $t('personal.share') }}: </span>
        <share-network
          v-for="item in listIcons"
          :key="item.id"
          class="d-flex text-decoration-none secondary-lighten rounded-circle ml-1"
          :network="item.network"
          :title="$t('referral.share_network', { name: adNetwork.name })"
          :url="link"
        >
          <v-icon color="primary" size="16" class="ma-1">
            {{ item.icon }}
          </v-icon>
        </share-network>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
  import CTextField from '@clickadilla/components/ui/CTextField.vue'
  import { mapState } from 'vuex'
  import CopyButton from '@/components/CopyButton.vue'

  export default {
    name: 'LinkCard',
    components: {
      CopyButton,
      CTextField
    },
    props: {
      isLoading: {
        type: Boolean,
        required: true
      },
      link: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        toolTipIsShown: false,
        toolTipIsDisabled: true,
        closeDelay: 0,
        listIcons: [
          { id: 1, network: 'telegram', icon: '$telegram' },
          { id: 2, network: 'linkedin', icon: '$linkedin' },
          { id: 3, network: 'twitter', icon: '$x-twitter' },
          { id: 4, network: 'facebook', icon: '$facebook' }
        ]
      }
    },
    computed: {
      ...mapState('settings', ['adNetwork']),
      isMobile() {
        return this.$vuetify.breakpoint.xsOnly
      }
    }
  }
</script>

<style lang="scss" scoped>
  .width-224 {
    width: 240px;
  }
</style>
